
var graypes_SERVER_IP = '';
var graypes_auiApp_URL = '';
var graypes_guestApiUrl = '';

$.getJSON('/js/graypes_configuration.json',function(data){ 
    graypes_SERVER_IP = data.graypes_SERVER_IP;
    graypes_auiApp_URL = data.graypes_auiApp_URL;
    graypes_guestApiUrl = data.graypes_guestApiUrl;
});

// Production on Digital-Ocean
// var graypes_SERVER_IP = 'http://159.65.18.208';
// var graypes_auiApp_URL = "http://159.65.18.208:4200";

// Local on Mac OS X localhost

var userService = {
    validateEmail: function(email, done, error) {
        $.ajax({
            type: "POST",
            url: graypes_SERVER_IP + '/gateway-api/api/v1/users/email/validate',
            contentType: 'application/json; charset=utf-8',
            data: JSON.stringify({ email: email }),
            crossDomain: true,
            xhrFields: {
                withCredentials: true
            },
            complete: function (data) {
                if (data.status !== 200) {
                    error();
                    return;
                }

                done(data.responseJSON);
            }
        });
    },
    newPassword: function (email, phoneNumber, done, error) {
        $.ajax({
            type: "POST",
            url: graypes_guestApiUrl,
            contentType: 'application/json; charset=utf-8',
            data: JSON.stringify({
                "userCategory":"PASSWORD RESET",
                "userType":"PASSWORD RESET",
                "userTechArea":"PASSWORD RESET",
                "userInterestedIn":"PASSWORD RESET",
                "userRegion":"PASSWORD RESET",
                "userName":"PASSWORD RESET",
                "mobile": phoneNumber,
                "email": email,
                "password":"PASSWORD RESET",
                "inURL":"PASSWORD RESET"
            }
            ),
            crossDomain: true,
            xhrFields: {
                withCredentials: true
            },
            complete: function (data) {
                if (data.status !== 200) {
                    error();
                    return;
                }

                done(data.responseJSON);
            }
        });
    }
}

$(document).ready(function () {
    var $invalidEmailAlert = $('.invalid-email-alert')

    $('#email,#lblMobile').keydown(function() {
        if($invalidEmailAlert.is(':visible')) {
            $invalidEmailAlert.hide();
        }
    });

    $('.forgot-password-form').submit(function (evt) {
        $('.invalid-email-alert').hide();
        evt.preventDefault();

        var email = $(this).find('#email').val();
        var phoneNumber = $(this).find('#lblMobile').val();

        userService.validateEmail(email,
            function (user) {
                if (!user || !user.id) {
                    $invalidEmailAlert.show();
                    return;
                }

                userService.newPassword(email, phoneNumber, function() {
                    uploadTabs('#sign-in-after-forgot-tab');
                }, function () {
                    $invalidEmailAlert.show();
                });
            }, function () {
                $invalidEmailAlert.show();
            });
    });
});